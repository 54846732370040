<template>
  <div class="business-visit">
    <div class="screen clearfix">
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="screen.name" placeholder="输入企业名称" style="width: 210px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>拜访开始时间</span>
        <div class="box">
          <el-date-picker
              v-model="visitStartTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              style="width: 230px"
              @change="handleStarTimeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>拜访结束时间</span>
        <div class="box">
          <el-date-picker
              v-model="visitEndTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd"
              style="width: 230px"
              @change="handleEndTimeChange"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="item">
        <span>商企经理</span>
        <div class="box">
          <el-select v-model="screen.admin_id" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 160px">
            <el-option
                v-for="item in dictData.businessManagerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>是否关联商机</span>
        <div class="box">
          <el-select v-model="screen.status" :popper-append-to-body="false" placeholder="全部"
                     style="width: 110px">
            <el-option
                v-for="item in dictData.isItRelatedOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <button class="button inquire" @click="search">查询</button>
        <button class="button" @click="resetQuery">重置</button>
      </div>
    </div>
    <el-table ref="multipleTable"
              :data="tableData.data"
              :border="true"
              v-loading="tableData.loading"
              style="width: 100%"
              @selection-change="handleSelectionChange">
      <el-table-column prop="company.company" label="企业名称"></el-table-column>
      <el-table-column label="拜访开始地点">
        <template slot-scope="scope">
          <span> {{ scope.row.start_location }} <span style="color: red" v-show="scope.row.start_location == '请点击重新定位，获取当前位置'">(范围外)</span></span>
        </template>
      </el-table-column>
      <el-table-column label="拜访开始时间" width="150">
        <template slot-scope="scope">
          <span><span v-if="scope.row.start_time == ''">-</span> <span v-else>{{ getTime(scope.row.start_time) }}</span> </span>
        </template>
      </el-table-column>
      <el-table-column label="拜访结束时间" width="150">
        <template slot-scope="scope">
          <span> <span v-if="scope.row.end_time == ''">-</span> <span v-else>{{ getTime(scope.row.end_time) }}</span> </span>
        </template>
      </el-table-column>
      <el-table-column prop="purpose" label="拜访目的" min-width="100%"></el-table-column>
      <el-table-column prop="adminInfo.account" label="商企经理" width="180"></el-table-column>
      <el-table-column label="是否关联商机" width="120" align="center">
        <template slot-scope="scope">
          <span :class="[scope.row.status == '1' ? 'color-green' : 'color-red']"> {{
              getIsrelevance(scope.row.status)
            }} </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <a href="JavaScript:;"><span class="color-blue" style="margin-right: 20px"
                                       @click="visitDetails(scope)">详情</span></a>
          <span v-if="scope.row.isItRelated === '是'" class="color-blue">查看商机</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.limit"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {company_company_list} from "../../request/businessOpportunityPool";
import {timeFormat} from "@/utils";

const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  name: '',
  start_time: null,
  end_time: null,
  visit_start_time: null,
  visit_end_time: null,
  status: null,
  admin_id: null,
}

export default {
  name: 'BusinessVisit',
  data() {
    return {
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
      visitStartTime: [],
      visitEndTime: [],
      dictData: {
        isItRelatedOptions: [{
          value: 1,
          label: '是'
        }, {
          value: 2,
          label: '否'
        }],
        businessManagerOptions: [],
      }
    }
  },
  mounted() {
    this.getDataList()
    //商企经理
    this.$api.optionUserList(1).then(res => {
      const data = res.data.map(item => {
        return {
          value: item.id,
          label: item.full_name
        }
      })
      this.$set(this.dictData, 'businessManagerOptions', data)
    })
  },
  methods: {
    visitDetails(scorp) { //详情跳转
      this.$router.push({path: '/visitDetail', query: {id: scorp.row.id}})
    },
    getTime(timestamp) {
      // 创建一个 Date 对象并传入时间戳
      let date = new Date(timestamp * 1000);

      // 使用 Date 对象的方法获取年、月、日、时、分、秒
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并且保证两位数输出
      let day = ("0" + date.getDate()).slice(-2); // 保证两位数输出
      let hours = ("0" + date.getHours()).slice(-2); // 保证两位数输出
      let minutes = ("0" + date.getMinutes()).slice(-2); // 保证两位数输出
      let seconds = ("0" + date.getSeconds()).slice(-2); // 保证两位数输出

      // 返回年月日时分秒字符串
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    },
    getIsrelevance(status) {
      if (status == '1') {
        return '是';
      } else if (status == '2') {
        return '否';
      }
    },
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页limit改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.addressData = []
      this.search();
    },
    /**
     * 获取表格列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.company_company_list(this.screen).then(res => {
        const tableData = res.data.list;
        this.$set(this.tableData, 'data', tableData);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    handleStarTimeChange(value) {
      this.screen.start_time = null;
      this.screen.end_time = null;
      if (value) {
        this.screen.start_time = value[0];
        this.screen.end_time = value[1];
      }
    },
    handleEndTimeChange(value) {
      this.screen.visit_start_time = null;
      this.screen.visit_end_time = null;

      if (value) {
        this.screen.visit_start_time = value[0];
        this.screen.visit_end_time = value[1];
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.business-visit {
  margin: 18px;
  padding: 30px 20px;
  background: #FFFFFF;

  .screen {
    padding-bottom: 10px;

    .item {
      float: left;
      margin-bottom: 10px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      span {
        float: left;
        margin-right: 12px;
        font-size: 12px;
        color: #21333F;
        line-height: 34px;
      }

      .box {
        float: left;
      }
    }

    .button {
      float: left;
      margin-left: 10px;
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      color: #6C7880;
      cursor: pointer;
    }

    .inquire {
      color: #FFFFFF;
      background: #3984F5;
    }
  }

  .el-table {
    .color-red {
      color: #FD5451;
    }

    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 日期样式修改
::v-deep .el-date-editor {
  padding: 0 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #CEDAE0;
  color: #242D33;

  .el-range-input {
    width: 38%;
    font-size: 12px;
    color: #242D33;
  }

  .el-range-separator {
    width: 6%;
    font-size: 12px;
  }

  .el-range__close-icon {
    margin-right: -5px;
    width: 18px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}
</style>
